import React, { useState } from "react";
import { category } from "../../category";
import Select from "./Select";

const AddPost = () => {
  const [categoryActive, setCategoryActive] = useState(false);
  const [select, setSelect] = useState("");
  const [chose, setChose] = useState("");
  const [chose2, setChose2] = useState("");
  return (
    <div>
      <div className="container">
        <h2>Разместить бесплатное обьявление</h2>
        <p>Загрузите фото (до 10 штук)</p>
        <input type="text" />
        <p>Описание</p>
        <textarea name="" id="" cols="30" rows="10" />
        <div>
          <p>Категория</p>
          <button
            type={"button"}
            onClick={() => {
              setCategoryActive(true);
              setSelect("");
              setChose("");
            }}
          >
            Выбрать
          </button>
          <ul style={{ display: categoryActive ? "block" : "none" }}>
            {category
              .filter((item) => item.category.includes(select))
              .map((item) => (
                <li>
                  <span onClick={() => setSelect(item.category)}>
                    {item.category}
                  </span>
                  <ul style={{ display: select ? "block" : "none" }}>
                    {item.list
                      .filter((el) => el.category.includes(chose))
                      .map((el) => (
                        <li onClick={() => setChose(el.category)}>
                          {el.category}
                          {el.list && (
                            <ul
                              style={{
                                display: chose.length ? "block" : "none",
                              }}
                            >
                              {el.list
                                .filter((list) => list.name.includes(chose2))
                                .map((list) => (
                                  <li onClick={() => setChose2(list.name)}>
                                    {list.name}
                                  </li>
                                ))}
                            </ul>
                          )}
                        </li>
                      ))}
                  </ul>
                </li>
              ))}
          </ul>
        </div>

        <Select title="Город" list={["Москва", "Краснодар"]} />

        <div>
          <p>Цена</p>
          <div>
            <input type="number" />
            <label htmlFor="">
              <span>RUB</span>
            </label>
          </div>
        </div>
        <div>
          <div>
            <p></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPost;
