import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import MyAccount from "./Pages/MyAccount/MyAccount";
import BusinessAccount from "./Pages/BusinessAccount/BusinessAccount";
import AnotherAccount from "./Pages/AnotherAccount/AnotherAccount";
import AddPost from "./Pages/AddPost/AddPost";
import Product from "./Pages/Product/Product";
import "./scss/style.scss";
import Header from "./layout/Header/Header";
import Popup from "./components/Popup/Popup";
import Footer from "./layout/Footer/Footer";
import { useEffect, useState } from "react";

function App() {
  const [user, setUser] = useState({});
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user")) !== null) {
      setUser(JSON.parse(localStorage.getItem("user")));
    }
  }, []);
  return (
    <div className="App">
      <Header user={user} setUser={setUser} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/MyAccount" element={<MyAccount />} />
        <Route path="/BusinessAccount" element={<BusinessAccount />} />
        <Route path="/AnotherAccount" element={<AnotherAccount />} />
        <Route path="/AddPost" element={<AddPost />} />
        <Route path="/Product/:id" element={<Product />} />
      </Routes>
      <Popup />
      <Footer />
    </div>
  );
}

export default App;
