export const category = [
  {
    category: "рыбалка",
    list: [
      { category: "Поплавочная" },
      { category: "Спининг" },
      { category: "Карповая" },
      { category: "Фидер" },
      { category: "Троллинг" },
      { category: "Зимняя" },
    ],
  },
  {
    category: "охота",
    list: [
      { category: "Пернатая дичь" },
      { category: "Пушной зверь" },
      { category: "Копытные" },
      { category: "Волк" },
      { category: "Медведь" },
    ],
  },
  {
    category: "спорт",
    list: [
      { category: "Cпиннинговая ловля" },
      { category: "Донная и поплавочная ловля" },
      { category: "Кружки" },
      { category: "Нахлыстовая ловля" },
      { category: "ловля на мормышку" },
      { category: "дорожка" },
    ],
  },
];
