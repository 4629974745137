import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="container">
          <div className="footer__copyright">
            <Link className="footer__copyright" to="https://bndc.ru">
              &copy;BNDC 2024
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
